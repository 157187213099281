import { LienStatusType, PaymentSourceType } from '+types/merchant';
import { switchTrxnMessage } from '+utils';

import { IBaseDisputesData } from './transactionDetails';

export type PayinType = {
  id: number;
  reference: string;
  status: 'success' | 'pending' | 'failed' | 'requires_auth' | 'processing' | 'flagged' | 'cancelled' | 'pre_authorized';
  amount: string;
  fee: string;
  vat: string;
  currency: 'NGN';
  amount_charged: string;
  amount_paid: string;
  payment_source_type: 'card' | 'bank_transfer' | 'payment_reversal' | 'mobile_money' | 'reserved_bank_account';
  channel: 'api' | 'modal' | 'web';
  narration: string | null;
  payment_reversals_type: number;
  meta: {
    stan: string;
    receipt: string;
    error_type?: 'CHARGE_PREVENTED' | 'CARD_RECORD_NOT_FOUND';
    card_details: {
      card_type: 'mastercard' | 'visa';
      first_six: string;
      last_four: string;
      masked_pan: string;
      expiry_year: string;
      expiry_month: string;
    };
    gateway_code?: string;
    charge_operation: 'capture';
    authorization_code: string;
    total_captured_amount: number;
    total_refunded_amount: number;
    total_authorized_amount: number;
    tokenization?: {
      can_tokenize: boolean;
    };
  };
  reason_for_failure: string;
  processor: string;
  processor_reference: string;
  transaction_reference: string;
  payment_reference: string;
  mobile_number: string | null;
  transaction_date: string;
  completed_at: string;
  amount_collected: string;
  source_amount: string;
  payment: {
    reference: string;
    account: {
      id: number;
      name: string;
    };
  };
};

export const payInsTabs = {
  transactions: 'transactions',
  metrics: 'metrics'
} as const;

export type TPayInsTabsKeys = keyof typeof payInsTabs;
export interface IPayinDetails {
  status: keyof typeof switchTrxnMessage;
  message: string;
  data: {
    reference: string;
    status: string;
    amount: string;
    amount_charged: string;
    amount_paid: string;
    amount_collected: string;
    fee: string;
    vat: string;
    narration: string;
    payment_source_type: string;
    payment_source_id: string;
    payment_id: number;
    currency: string;
    channel: string;
    payment_reversals_type: number;
    meta: {
      gateway_code: string;
      error_type: string;
      card_details: {
        card_type: string;
        first_six: string;
        last_four: string;
        masked_pan: string;
        expiry_year: string;
        expiry_month: string;
      };
      tokenization: {
        can_tokenize: boolean;
        store_for_future_use: boolean;
      };
      charge_operation: string;
    } | null;
    auth_data: {
      pre_auth_expiry: string;
      auth_model: string;
    };
    message: string;
    processor: string;
    processor_reference: string;
    merchant_bears_cost: boolean;
    transaction_date: string;
    completed_at: string;
    payment: {
      reference: string;
      unique_reference: string;
      customer: {
        name: string;
        email: string;
      };
      sentinal_transaction: any;
      payment_conversion: any;
      account: {
        id: number;
        name: string;
      };
    };
    source: {
      card: string;
      type: string;
      details: {
        masked_pan: string;
        card_type: string;
        bank_name: string;
        account_number: string;
        bank_slug: string;
        account_name: string;
        network_provider: string;
        mobile_number: string;
      };
      virtual_bank_account: {
        account_number: string;
        bank_name: string;
        expiry: string;
        account_name: string;
        status: string;
      };
    };
    can_reverse_payment: boolean;
    can_request_webhook: boolean;
    merchant_id: number;
    lien: {
      reference: string;
      status: LienStatusType;
      source_type: PaymentSourceType;
      source_reference: string;
    };
  };
}

export interface IGenerateMoreDetailFromProps {
  data: IPayinDetails['data'];
  triggerReasonForFailureModal: () => void;
  state: {
    clickedTransactionIds?: string[];
  };
  updateTransactionStatusModalState: (state: boolean, data: IPayinDetails['data']) => void;
  userAccess: { [key: string]: boolean };
}

export interface BankDetailsInfo {
  account: string;
  bank: string;
}
export interface IBank {
  name: string;
  code: string;
}

export interface IAttempt {
  id: string;
  created_at: string;
  reference: string;
  status?: string;
  bank_name?: string;
  account_number?: string;
  account_name?: string;
  message?: string;
  trace_id?: string;
}

export interface IApprovalData {
  reference: string;
  action?: string;
}

export interface IDeclineData {
  reference: string;
  action?: string;
}

export interface IRefundStatus {
  name: string;
  color: string;
  icon: string | JSX.Element;
}

export interface IReversalModalContent {
  size?: string;
  showButtons?: boolean;
  heading?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  description?: JSX.Element;
  secondButtonActionIsTerminal?: boolean;
  secondButtonAction?: () => void;
  firstButtonAction?: () => void;
  completedHeading?: string;
  completedDescription?: string;
  secondButtonDisable?: boolean;
  content?: JSX.Element;
}

export interface ISwitchReversalModal {
  visible?: boolean;
  close?: () => void;
  completedHeading?: string;
  size?: string;
  showButtons?: boolean;
  heading?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  description?: JSX.Element;
  secondButtonActionIsTerminal?: boolean;
  secondButtonAction?: () => void;
  firstButtonAction?: () => void;
}

export interface IRetryDetail {
  account_number: string;
  bank_code: string;
  reference: string;
}

export interface IBankResponse {
  bank_code: string;
  account_number: string;
  account_name?: string;
}

export interface IRetryData {
  reference: string;
  action?: string;
}

export interface IReversalControl {
  refunds: Partial<IBaseDisputesData>[];
  chargebacks: Partial<IBaseDisputesData>[];
  reversals: Partial<IBaseDisputesData>[];
}
