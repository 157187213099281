import React, { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';
import { UseMutateAsyncFunction, useMutation, useQueryClient } from 'react-query';

import ManageAccForm from '+containers/Dashboard/Shared/ManageAccForm';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

import './vba-reprocess.scss';

type TReprocessReasonModalContent = { [key in 'setReason' | 'setReasonText']: Dispatch<SetStateAction<string>> } & {
  setModalToRender: (arg: string) => void;
  type: string;
  mutateAsync: UseMutateAsyncFunction;
};

type TVbaReprocessModal = { [key in 'reference' | 'trxStatus' | 'accStatus' | 'currency' | 'id']: string } & { close: () => void };

const api = new APIRequest();

const reasons = [
  { label: 'Select Reason', value: '' },
  { label: 'Account Status Change', value: 'Account Status Change' },
  { label: 'Validation Error', value: 'Validation Error' },
  { label: 'Documents Verification', value: 'Documents Verification' },
  { label: 'Other', value: 'other' }
];

const modalContent = ({ setReason, setReasonText, setModalToRender, mutateAsync }: TReprocessReasonModalContent) =>
  ({
    reprocess: {
      heading: 'Reprocess this transaction',
      description: 'Please confirm that you want to reprocess this transaction',
      content: (
        <ManageAccForm
          headerText="Select Reason"
          options={reasons}
          setReason={setReason}
          setReasonText={setReasonText}
          contentText="Add your own reason"
        />
      ),
      secondButtonAction: () => setModalToRender('confirmReprocess'),
      secondButtonText: 'Reprocess',
      secondButtonActionIsTerminal: false,
      size: 'md'
    },
    confirmReprocess: {
      heading: 'Are you sure you want to reprocess this transaction?',
      content: (
        <p style={{ marginTop: -15 }}>
          Please note that the value of this transaction will be settled to the merchant&apos;s available balance if you reprocess this
          transaction.
        </p>
      ),
      completedHeading: 'Done!',
      completedDescription: 'You have successfully reprocessed this transaction.',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Reprocess',
      secondButtonAction: mutateAsync,
      size: 'md'
    }
  }) as unknown as { [key: string]: IModalProps };

const VbaReprocessModal: FC<TVbaReprocessModal> = ({ trxStatus, accStatus, reference, currency, id, close }) => {
  const [modal, setModal] = useState({} as IModalProps);
  const [reason, setReason] = useState('');
  const [reasonText, setReasonText] = useState('');
  const { feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(() => api.vbaTransactionReprocess({ id: reference, reason: reasonText || reason, currency }), {
    onSuccess: () => {
      queryClient.invalidateQueries(`PAYIN_DETAILS_${id}`);
      queryClient.invalidateQueries('TRANSACTIONS');
      queryClient.invalidateQueries('ACCOUNT_HOLDER_TABLE');
    },
    onError: (error: { response: { data: { message: string } } }) => {
      logError(error);
      feedbackInit({
        message: `There has been an error reprocessing this transaction. Please try again`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const setModalToRender = (type?: string) => {
    if (!type) {
      setModal({} as IModalProps);
      return;
    }
    const modalDetails = modalContent({ setReason, setReasonText, setModalToRender, type, mutateAsync });

    setModal({
      heading: modalDetails[type].heading,
      description: modalDetails[type].description,
      completedHeading: modalDetails[type].completedHeading,
      completedDescription: modalDetails[type].completedDescription,
      content: modalDetails[type].content,
      secondButtonText: modalDetails[type].secondButtonText,
      secondButtonAction: modalDetails[type].secondButtonAction,
      secondButtonActionIsTerminal: modalDetails[type].secondButtonActionIsTerminal,
      size: modalDetails[type].size,
      secondButtonColor: modalDetails[type]?.secondButtonColor,
      showButtons: modalDetails[type]?.showButtons,
      hideSecondButton: modalDetails[type]?.hideSecondButton,
      firstButtonText: modalDetails[type]?.firstButtonText
    } as unknown as IModalProps);
  };

  const disableModalProceedBtn = (reason === 'other' && !reasonText.trim()) || !reason.trim();

  useEffect(() => {
    setModalToRender('reprocess');
  }, []);

  useEffect(() => {
    if (Object.keys(modal).length) return;
    setReason('');
    setReasonText('');
  }, [Object.keys(modal).length]);

  return (
    <div className="vba-reprocess">
      {Object.keys(modal).length > 0 && (
        <Modal
          heading={modal.heading}
          description={modal.description}
          completedHeading={modal.completedHeading}
          completedDescription={modal.completedDescription}
          content={modal.content}
          secondButtonText={modal.secondButtonText}
          secondButtonDisable={disableModalProceedBtn}
          secondButtonAction={modal.secondButtonAction}
          secondButtonActionIsTerminal={modal.secondButtonActionIsTerminal}
          size={modal.size}
          secondButtonColor={modal?.secondButtonColor}
          showButtons={modal?.showButtons}
          hideSecondButton={modal?.hideSecondButton}
          firstButtonText={modal?.firstButtonText}
          close={() => {
            setModal({} as IModalProps);
            close();
          }}
        />
      )}
    </div>
  );
};

export default memo(VbaReprocessModal);
