import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import LienModal from '+containers/Dashboard/Merchants/MerchantDetails/components/Balances/components/LienModal';
import { useFeedbackHandler, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import { CurrencyType, IPayinDetails, IReversalControl, LienStageType, TRelatedTransactions, TTransactionDetails } from '+types';
import { formatAmount, history, isAllowed, isObjectNotEmpty, logError, switchTrxnMessage } from '+utils';

import FailedPayoutReasons from '../../Shared/FailedPayoutReasonsModal';
import ReversalsInfoModal from '../../Shared/ReversalsInfoModal';
import TransactionBreakdownModal from '../../Shared/TransactionBreakdownModal';
import TransactionStatusModal from '../../Shared/TransactionStatus';
import TransactionDetails from '../../TransactionDetailsNew';
import {
  childrenGenerators,
  generateMoreDetailFrom,
  generatePayersInfoFrom,
  generateSummaryFrom,
  generateVirtualAccountInfoFrom,
  placeLienModalButton,
  reprocessButtonAction,
  showRelatedTransactions,
  summaryGenerators
} from './payinDetailsHelper';
import VbaReprocessModal from './widgetsPayInDetails/VbaReProcessModal/VbaReprocessModal';

const api = new APIRequest();

const PayInDetails = () => {
  const { id } = useParams<Record<string, string>>();
  const { feedbackInit } = useFeedbackHandler();

  const userAccess = useSetUserAccess() as { [key: string]: boolean };
  const hasAccess = isAllowed(userAccess, ['pay_in_details.view']);

  const [modal, setModal] = useState('');
  const [manageLienModal, setManageLienModal] = useState<LienStageType>('');

  const [reversalsControls, setReversalsControls] = useState<IReversalControl>({
    refunds: [],
    chargebacks: [],
    reversals: []
  });

  useEffect(() => {
    if (userAccess && !hasAccess) {
      history.push('/dashboard/access-denied', null);
    }
  }, [userAccess]);

  const { data, isLoading } = useQuery(`PAYIN_DETAILS_${id}`, () => api.getSingleTransaction('payins', id), {
    refetchOnMount: 'always',
    onError: e => {
      logError(e);
      feedbackInit({
        message: `There has been an error fetching the details for the payout: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    },
    enabled: !!hasAccess,
    onSuccess: () => {
      sessionStorage.setItem('payinId', id);
    }
  });

  const {
    amount_charged: amountCharged,
    amount_paid: netAmount,
    amount_collected: amountCollected,
    currency,
    fee,
    vat,
    payment,
    meta
  } = (data || {}) as TTransactionDetails;

  const { data: relatedTransactions, isLoading: isLoadingRelatedTransactions } = useQuery(
    ['RELATED_TRANSACTIONS', id],
    () => api.getRelatedTransactions(data?.payment?.unique_reference),
    {
      enabled: !!data && !!data?.payment?.unique_reference,

      onError: e => {
        logError(e);
        feedbackInit({
          message: 'There was an error getting related transactions',
          type: 'danger',
          isClosable: true
        });
      }
    }
  );

  const relatedTrxnsWithoutCurrentTransaction = relatedTransactions?.data.filter(
    (trx: TRelatedTransactions) => trx.reference !== data.reference
  );

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();
  const isMultiple = relatedTrxnsWithoutCurrentTransaction?.length > 1;
  const hasCreateLienAccess = isAllowed(userAccess, ['lien.create']) && currency === 'NGN' && data?.payment_reversals_type === 0;

  const closeModal = () => setModal('');

  const triggerReasonForFailureModal = () => {
    setModal('reason_for_failure');
  };

  const goToRelatedTransaction = () => {
    const uniqueRef = data?.payment?.unique_reference;
    history.push(`/dashboard/pay-ins?dateFrom=all_time&dateTo=all_time&page=1&sorterType=filter&uniqueReference=${uniqueRef}`);
  };

  const summaries = generateSummaryFrom(data as IPayinDetails['data']);
  const moreDetails = generateMoreDetailFrom({ data, triggerReasonForFailureModal, state, updateTransactionStatusModalState, userAccess });
  const receipientInfo = generatePayersInfoFrom(data as IPayinDetails['data']);
  const virtualAccount = generateVirtualAccountInfoFrom(data?.source as IPayinDetails['data']['source']);

  const hasDisputes = (refunds: undefined | Array<unknown>, reversals: undefined | Array<unknown>, chargeback: undefined | unknown) => {
    const refundsExist = refunds && refunds.length > 0;
    const reversalsExist = reversals && reversals.length > 0;
    const chargebackExists = isObjectNotEmpty(chargeback as object);
    return refundsExist || chargebackExists || reversalsExist;
  };

  const { data: disputesList } = useQuery(['DISPUTES_LIST', data?.reference], () => api.getPayinReversals(data?.reference), {
    enabled: data !== undefined,
    onSuccess: disputesData => {
      formatReversalsData(disputesData?.reversals || []);
    },
    onError: e => {
      logError(e);
    }
  });

  const formatReversalsData = (reversalsData: { type: string }[] = []) => {
    const buffer = {
      refunds: reversalsData.filter(({ type }) => type === 'refund'),
      chargebacks: reversalsData.filter(({ type }) => type === 'chargeback'),
      reversals: reversalsData.filter(({ type }) => type === 'overpayment' || type === 'underpayment')
    } as IReversalControl;
    setReversalsControls(buffer);
  };

  const disputes = {
    refunds: reversalsControls?.refunds || [],
    chargebacks: reversalsControls?.chargebacks || [],
    reversals: reversalsControls?.reversals || []
  };

  return (
    <section style={{ padding: '40px' }}>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount_charged as number)}
          currency={data?.currency}
          statusLabels={[
            {
              status: switchTrxnMessage[data?.status as IPayinDetails['status']]?.name,
              statusBg: switchTrxnMessage[data?.status as IPayinDetails['status']]?.backgroundColor,
              statusColor: switchTrxnMessage[data?.status as IPayinDetails['status']]?.color
            },
            {
              status: switchTrxnMessage[data?.lien?.status as IPayinDetails['status']]?.name,
              statusBg: switchTrxnMessage[data?.lien?.status as IPayinDetails['status']]?.backgroundColor,
              statusColor: switchTrxnMessage[data?.lien?.status as IPayinDetails['status']]?.color,
              hide: !data?.lien || data?.status !== 'success'
            }
          ]}
          isLoading={isLoading}
          summaries={summaries}
          actionButtons={[
            ...placeLienModalButton({ data, onClick: () => setManageLienModal('place'), hasCreateLienAccess }),
            ...reprocessButtonAction({ data, onClick: () => setModal('reprocess') })
          ]}
        />

        <TransactionDetails.Section
          isLoading={isLoading}
          heading="More Transaction Details"
          summaries={moreDetails}
          showLink
          handleLinkClick={() => setModal('transaction_breakdown')}
        />

        <TransactionDetails.Section isLoading={isLoading} heading="Payer's Information" summaries={receipientInfo} />

        {!!relatedTrxnsWithoutCurrentTransaction?.length && (
          <>
            <TransactionDetails.Section
              isLoading={isLoadingRelatedTransactions}
              heading={`${isMultiple ? 'All' : 'Related'} Transactions`}
              showLink
              linkText={`See ${isMultiple ? 'All' : ''} Related Transaction${isMultiple ? 's' : ''}`}
              handleLinkClick={goToRelatedTransaction}
            />
            <>{showRelatedTransactions({ uniqueRef: payment.unique_reference, transactions: relatedTrxnsWithoutCurrentTransaction })}</>
          </>
        )}

        {data?.source?.virtual_bank_account && (
          <TransactionDetails.Section isLoading={isLoading} heading="Customer's Virtual Account" summaries={virtualAccount} />
        )}

        <TransactionDetails.Section
          isLoading={isLoading}
          heading="Refunds, Reversals & Chargebacks"
          showLearnMoreBtn={true}
          openLearnMoreModal={() => setModal('learn_more')}
        >
          <TransactionDetails.Disputes
            tabs={['refunds', 'reversals', 'chargebacks']}
            disputesGenerators={hasDisputes(disputesList?.refunds, disputesList?.reversals, disputesList?.chargeback) ? disputes : null}
            summaryGenerators={summaryGenerators}
            childrenGenerators={childrenGenerators}
            currency={currency as CurrencyType}
          />
        </TransactionDetails.Section>
      </TransactionDetails>

      {modal === 'learn_more' && <ReversalsInfoModal close={closeModal} visible={modal === 'learn_more'} />}

      {modal === 'reason_for_failure' && (
        <FailedPayoutReasons close={closeModal} visible={modal === 'reason_for_failure'} transactions={data} />
      )}

      {state.openTransactionStatusModal && (
        <TransactionStatusModal
          activeTransaction={state.activeTransaction}
          updateModalState={updateTransactionStatusModalState}
          triggerProcessingLoader={handleProcessingLoader}
          transactionType={'payins'}
        />
      )}

      {modal === 'transaction_breakdown' && (
        <TransactionBreakdownModal
          transactionData={{
            currency,
            amount_charged: formatAmount(amountCharged),
            amount_paid: formatAmount(amountCollected),
            fees: formatAmount(+fee + (+vat || 0)),
            ...(meta?.additional_fees?.stamp_duty && { stamp_duty_fee: meta?.additional_fees?.stamp_duty }),
            ...(payment?.sentinal_transaction
              ? {
                  tax: formatAmount(Number(payment.sentinal_transaction.vat) + Number(payment.sentinal_transaction.processing_fee)),
                  net_amount: formatAmount(
                    +netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee)
                  )
                }
              : { net_amount: netAmount })
          }}
          close={closeModal}
        />
      )}
      {manageLienModal && (
        <LienModal
          lienStage={manageLienModal}
          setLienStage={(value: LienStageType) => setManageLienModal(value)}
          currency={currency as CurrencyType}
          amount={amountCharged}
          paymentReference={data?.reference}
          merchantName={data?.payment?.account?.name}
        />
      )}
      {modal === 'reprocess' && (
        <VbaReprocessModal
          close={closeModal}
          trxStatus={data?.status}
          accStatus={data?.source?.virtual_bank_account.status}
          reference={data?.reference}
          currency={currency as CurrencyType}
          id={id}
        />
      )}
    </section>
  );
};

export default PayInDetails;
